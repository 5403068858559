module.exports = [{
      plugin: require('C:/wamp64/www/github-react-theme/ecommerce/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/wamp64/www/github-react-theme/ecommerce/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"tomato","showSpinner":false},
    },{
      plugin: require('C:/wamp64/www/github-react-theme/ecommerce/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/wamp64/www/github-react-theme/ecommerce/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
